import { FiltersProps } from '@/stores/filters';

const API_CONFIG = {
  ROOT_URL: process.env.API_URL,
  PAGE_SIZE: Number(process.env.PAGE_SIZE) || 20,
};

export const API_ENDPOINTS = {
  CERTIFICATIONS_FOR_TASK: (
    taskId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `tasks/${taskId}/certifications?${params}`;
  },
  CERTIFICATIONS_FOR_EMAIL: (
    emailId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `emails/${emailId}/certifications?${params}`;
  },
  CERTIFICATIONS_FOR_WORKFLOW: (
    workflowId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `workflows/inspection/${workflowId}/certifications?${params}`;
  },
  USER_CERTIFICATIONS: (filters: FiltersProps, page = 1): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (filters) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `certifications/list?${params}`;
  },
  CERTIFICATION_FAVORITE: (identifier: string) =>
    `/certifications/${identifier}/favorite`,
  DOWNLOAD_ASSET: (identifier: string): string =>
    `certifications/download_file_for_user/${identifier}`,
  DOWNLOAD: (identifier: string): string =>
    `certifications/download/${identifier}`,
  DOWNLOAD_TASK: (identifier: string): string => `tasks/download/${identifier}`,
  DOWNLOAD_WORKFLOW: (identifier: string): string =>
    `workflows/download/${identifier}`,
  DOWNLOAD_INSPECTION: (identifier: string): string =>
    `/inspections/download/${identifier}`,
  LINK_DETAILS: (id: string | string[]): string =>
    `/certifications/link_details/${id}`,
  CERTIFICATION_DETAILS: (identifier?: string): string =>
    `certifications/details/${identifier}`,
  USER_PROFILE: (): string => 'auth/me',
  SUBSCRIPTION_INFORMATION: (): string => 'auth/subscription_information',
  TASK_DETAILS: (identifier?: string): string => `tasks/${identifier}`,
  TASK_DETAILS_WITH_USER_DATA: (identifier?: string): string =>
    `tasks/get_task_with_users_data/${identifier}`,
  WORKFLOWS_DETAILS_WITH_USER_DATA: (identifier: string): string =>
    `workflows/get_workflow_with_users_data/${identifier}`,
  TASK_FOLDERS: (): string => `/tasks/folders`,
  TASK_FOLDER: (identifier?: string): string => `/folders/${identifier}`,
  TASK_FOLDER_DELETE: (identifier: string | null): string =>
    `/folders/${identifier}`,
  TASK_FOLDER_SAVE: (identifier: string): string =>
    `/tasks/folder/${identifier}`,
  TASK_FOLDER_CREATE: (): string => '/tasks/folder/create',
  USERS_FOR_TASK: () => '/tasks/users_for_task',
  USER_DETAILS: (identifier?: string): string => `users/${identifier}`,
  TOGGLE_REQUIRE_METADATA: (): string => '/auth/mandatory_metadata',
  TASK_FORM_DETAILS: (identifier?: string): string =>
    `/tasks/${identifier}/form`,
  REPORTS_FOR_SELECT_LIST: (): string => '/reports/list_for_select',
  REPORTS_GENERATE: (): string => '/reports/generate',
  WORKFLOW_FOLDERS: (): string => `/workflows/folders`,
  WORKFLOW_FOLDER: (identifier?: string): string => `/folders/${identifier}`,
  WORKFLOW_FOLDER_DELETE: (identifier: string | null): string =>
    `/folders/${identifier}`,
  WORKFLOW_FOLDER_SAVE: (identifier: string): string =>
    `/workflows/folder/${identifier}`,
  WORKFLOW_FOLDER_CREATE: (): string => '/workflows/folder/create',
  WORKFLOW: (identifier: string): string => `/workflows/${identifier}`,
  WORKFLOW_ARCHIVE: (identifier: string | null): string =>
    `/tasks/archive/${identifier}`,
  WORKFLOW_DELETE: (identifier: string | null): string =>
    `/workflows/${identifier}`,
  WORKFLOW_SAVE: (identifier: number): string => `/workflows/${identifier}`,
  WORKFLOW_CREATE: (): string => '/workflows/create',
  WORKFLOWS: (): string => '/workflows',
  INSPECTIONS: (identifier: string): string =>
    `/workflows/${identifier}/inspections`,
  INSPECTION_SAVE: (identifier: number): string =>
    `/workflows/inspection/${identifier}`,
  INSPECTION_DELETE: (identifier: number): string =>
    `/inspections/${identifier}`,
  INSPECTION_FORM: (identifier: string): string =>
    `/inspections/form/${identifier}`,
  TEMPLATE: (identifier: string): string => `/templates/${identifier}`,
  EMAILS: (): string => `/emails`,
  EMAIL_TASK_ARCHIVE: (identifier: string | null): string =>
    `/emails/archive/${identifier}`,
  EMAIL_TASK_DELETE: (identifier: string | null): string =>
    `/emails/${identifier}`,
  CLIENTS: () => `/tasks/clients_for_task`,
};

export default API_CONFIG;
